<template>
  <section id="dashboard-historico-planificacion">
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <ag-grid-table :configUrl="historicoPlanificacionesConfig" :dataUrl="historicoPlanificacionesData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
import useApiServices from '@/services/useApiServices.js';
import axios from "@axios";
import PlanificacionActions from "./PlanificacionActions.vue";
import DashGlobalActions from "./DashGlobalActions.vue";
import {
  BRow,
  BCol,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
export default {
  components: {
    AgGridTable,
    RepeatStatistics,
    BRow,
    BCol,
    VBPopover,
    VBTooltip,
    PlanificacionActions: PlanificacionActions,
    DashGlobalActions: DashGlobalActions
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  data() {
    return {
      historicoPlanificacionesConfig: useApiServices.historicoPlanificacionesConfig,
      historicoPlanificacionesData: useApiServices.historicoPlanificacionesData,
      // edit: useApiServices.historicoPlanificacionesEdit,
      itemsData: [],
    };
  },
  mounted() {
    axios
      .get(useApiServices.historicoPlanificacionesStatistics)
      .then((response) => {
        this.itemsData = response.data;
      })
      .catch((error) => { });
  },
}
</script>

<style lang="scss" scoped>

</style>